import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URL, CM_API_URL } from "../../../../app.component";
import { HttpHeaders } from "@angular/common/http";
import { TokenStorage } from "./../../../../token.storage";
import { Property } from "app/views/bookone/property/property";
import { Room } from "../../../bookone/room/room";
import { OTAPropertyMapping } from "../../otaProperty";
import { OTARoomMapping } from "../../otaRoom";
import { OTAChannelPropertyDTO } from "../../../bookone-admin/otaPropertyDTO/ChannelManagerPropertyDTO";
import { PointOfSale } from "app/views/master-service/add-point-of-sale-dialog/pointOfSale";
import { ServiceTypeList } from "../../service-type-list/serviceType";
import { PropertySequence } from "../../property-sequence/propertySequence";
import { RoomDetails } from "app/views/bookone/booking/RoomDetails";
import { PropertyPayment } from "../propertyPayment";
import { Audit } from "app/audit";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  constructor(private http: HttpClient, private token: TokenStorage) {}

  headers = new Headers();
  httpOptions = {
    headers: new HttpHeaders({
      USER_ID: this.token.getUserId(),
    }),
  };

  findPropertyByShortName(shortName: String) {
    return this.http.get<any>(
      API_URL + "/api/property/findByShortName?shortName=" + shortName,
      { observe: "response" }
    );
  }

  updateRoomDetailStatusInCheckedInFrom(roomstatus: RoomDetails) {
    return this.http.post<RoomDetails>(
      API_URL + "/api/roomDetails/status",
      roomstatus,
      { observe: "response" }
    );
  }


  updateProperty(property: Property): Observable<Property> {
    return this.http.post<Property>(
      API_URL + "/api/property/user/update/property",
      property,
      this.httpOptions
    );
  }

  getPropertySequence(propertyId: number) {
    return this.http.get<PropertySequence[]>(
      API_URL + "/api/property/sequence/" + propertyId,
      { observe: "response" }
    );
  }

  getOrgServiceByOrgIdAndServiceType(
    organizationId: number,
    serviceType: string
  ) {
    return this.http.get<ServiceTypeList[]>(
      API_URL +
        "/api/property/service/organisation/" +
        organizationId +
        "/businessType/" +
        serviceType +
        "/",
      { observe: "response" }
    );
  }

  getPropertiesDetailsByUserId(userId: string): Observable<Property> {
    return this.http.get<Property>(
      API_URL + "/api/property/findByUserId/" + userId
    );
  }

  deActivateProperty(propertyId: string) {
    return this.http.get<any>(
      API_URL + "/api/property/deactivateBusiness/" + propertyId
    );
  }

  markAsFeatured(propertyId: string) {
    return this.http.get<any>(
      API_URL + "/api/property/markAsFeatured/" + propertyId,
      { observe: "response" }
    );
  }

  upgradePlan(propertyId: number, planName: string) {
    return this.http.get<any>(
      API_URL + "/api/property/" + propertyId + "/upgradePlan/" + planName
    );
  }

  removeFromFeaturedLsit(propertyId: string) {
    return this.http.get<any>(
      API_URL + "/api/property/removeFromFeaturedLsit/" + propertyId,
      { observe: "response" }
    );
  }

  activateProperty(propertyId: string) {
    return this.http.get<any>(
      API_URL + "/api/property/activateBusiness/" + propertyId
    );
  }

  getPropertyDetailsByPropertyId(propertyId: number): Observable<Property> {
    return this.http.get<Property>(
      API_URL + "/api/thm/findByPropertyId/" + propertyId,
      this.httpOptions
    );
  }

  getRoomDetailsByPropertyId(propertyId: number): Observable<Room[]> {
    return this.http.get<Room[]>(
      API_URL + "/api/property/" + propertyId + "/rooms"
    );
  }

  deleteRoomByRoomId(roomId: number, propertyId: number) {
    return this.http.post<any>(
      API_URL +
        "/api/room/property/" +
        propertyId +
        "/user/delete/room/" +
        roomId,
      { observe: "response" }
    );
  }

  deleteRoomDetailsFromRoom(
    propertyId: number,
    roomDetails: any,
    userId: string
  ) {
    let headers = new HttpHeaders({
      USER_ID: userId,
    });
    return this.http.post<any>(
      API_URL +
        "/api/room/property/" +
        propertyId +
        "/room/" +
        roomDetails.roomId +
        "/delete/roomDetails",
      roomDetails,
      { headers: headers }
    );
  }

  propertyMapping(channelId: number, otaPropertyMapping: OTAPropertyMapping) {
    return this.http.post<OTAPropertyMapping>(
      CM_API_URL + "/api/channelManager/" + channelId + "/add/property",
      otaPropertyMapping,
      { observe: "response" }
    );
  }

  roomMapping(
    channelId: number,
    propertyId: number,
    otaRoomMapping: OTARoomMapping
  ) {
    return this.http.post<OTARoomMapping>(
      CM_API_URL +
        "/api/channelManager/" +
        channelId +
        "/bookOneproperty/" +
        propertyId +
        "/add/room",
      otaRoomMapping,
      { observe: "response" }
    );
  }

  getConfiguredPropertyDetailsByOrganizationId(
    organizationId: number
  ): Observable<OTAChannelPropertyDTO[]> {
    return this.http.get<OTAChannelPropertyDTO[]>(
      CM_API_URL + "/api/channelManager/organisation/" + organizationId
    );
  }

  getConfiguredPropertyDetailsByPropertyId(
    propertyId: number
  ): Observable<OTAChannelPropertyDTO> {
    return this.http.get<OTAChannelPropertyDTO>(
      CM_API_URL + "/api/channelManager/property/" + propertyId
    );
  }

  getAllPointOfSale(propertyId: number): Observable<PointOfSale[]> {
    return this.http.get<PointOfSale[]>(
      API_URL + "/api/property/" + propertyId + "/pointOfSale"
    );
  }

  savePointOfSaleToProperty(pointOfSale: PointOfSale) {
    return this.http.post<PointOfSale>(
      API_URL + "/api/property/" + pointOfSale.propertyId + "/pointOfSale",
      pointOfSale,
      { observe: "response" }
    );
  }

  saveConfiguredPropertyDetailsByPropertyId(
    propertyId: number,
    oTAChannelPropertyDTO: OTAChannelPropertyDTO
  ): Observable<OTAChannelPropertyDTO> {
    return this.http.post<OTAChannelPropertyDTO>(
      CM_API_URL + "/api/channelManager/property/" + propertyId,
      oTAChannelPropertyDTO
    );
  }
  createChannelManager(oTAChannelPropertyDTO: OTAChannelPropertyDTO) {
    return this.http.post<OTAChannelPropertyDTO>(
      CM_API_URL + "/api/channelManager/save",
      oTAChannelPropertyDTO,
      { observe: "response" }
    );
  }
  validateChannel(oTAChannelPropertyDTO: OTAChannelPropertyDTO) {
    return this.http.post<OTAChannelPropertyDTO>(
      CM_API_URL + "/api/channelManager/validate",
      oTAChannelPropertyDTO,
      { observe: "response" }
    );
  }
  addPropertyToChannelManager(
    channelId,
    oTAChannelPropertyDTO: OTAChannelPropertyDTO
  ) {
    return this.http.post<OTAChannelPropertyDTO>(
      CM_API_URL + "/api/channelManager/" + channelId + "/add/property",
      oTAChannelPropertyDTO,
      { observe: "response" }
    );
  }
  getAllChannel(): Observable<any[]> {
    return this.http.get<PointOfSale[]>(
      API_URL + "/api/channelManager/findAll"
    );
  }

  // property payment

  createPropertyPayment(propertyPayment: PropertyPayment){
    return this.http.post<PropertyPayment>(
      API_URL + "/api/propertyPayment/create",propertyPayment
    );
  }

  deletePropertyPayment(propertyPayment: PropertyPayment){
    return this.http.post<PropertyPayment>(
      API_URL + "/api/propertyPayment/delete/"+propertyPayment.id,propertyPayment
    );
  }

  getAllPaymentByOrganizationId(organizationId): Observable<any[]> {
    return this.http.get<PropertyPayment[]>(
      API_URL + "/api/propertyPayment/getAllByOrganizationId/"+organizationId
    );
  }

  getAllPaymentBypropertyId(propertyId): Observable<any[]> {
    return this.http.get<PropertyPayment[]>(
      API_URL + "/api/propertyPayment/getAllByPropertyId/"+propertyId
    );
  }

  createAuditReport(audit: Audit) {
    // Logger.log(booking);
    return this.http.post<Audit>(
      API_URL + "/api/auditReports/",
      audit,
      { observe: "response" }
    );
  }

  getAllAuditReportByPropertyId(propertyId): Observable<any[]> {
    return this.http.get<Audit[]>(
      API_URL + "/api/auditReports/getByPropertyId?propertyId="+propertyId
    );
  }

  getAllAuditReportByBookingId(bookingId){
    return this.http.get<Audit[]>(
      API_URL + "/api/auditReports/getByBookingId?bookingId="+bookingId
    );
  }

  getAllAuditReportByOrderId(OrderId){
    return this.http.get<Audit[]>(
      API_URL + "/api/auditReports/getOrderId?orderId="+OrderId
    );
  }

}
